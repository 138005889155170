import { Pod } from './../CategoryTypes'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { handleSelectItems } from 'components/DataTableSlim/Utils'

export const useSelectDisplays = () => {
  const {
    selectedItems: currentSelectedItems,
    setSelectedItems,
  } = useDataTable<Pod>(DataTableInstance.Categories)

  const selectDisplays = (displays: Pod[], isSelected: boolean) => {
    const newSelectedItems = handleSelectItems<Pod>(
      currentSelectedItems,
      item => item.displayId ?? '',
      displays,
      isSelected,
    )
    if (!isSelected && newSelectedItems.includedIds) {
      const excludeIds = displays.map(pod => pod.displayId ?? '')
      newSelectedItems.includedIds = newSelectedItems.includedIds.filter(
        id => !excludeIds.includes(id),
      )
      newSelectedItems.items = newSelectedItems.items.filter(
        item => !excludeIds.includes(item.displayId),
      )
      newSelectedItems.ids = newSelectedItems.ids.filter(
        id => !excludeIds.includes(id),
      )
    }
    setSelectedItems(newSelectedItems)
  }

  const unselectDisplays = () => {
    setSelectedItems({
      items: [],
      ids: [],
    })
  }

  return {
    selectDisplays,
    unselectDisplays,
  }
}
