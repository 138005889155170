import React from 'react'

import CategoryEditor from './Editor'
import { CategoriesTable } from './CategoriesTable/CategoriesTable'
import styles from './index.module.scss'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { Category, Pod } from './CategoryTypes'
import {
  CategoryManagerPodsQueryResult,
  CategoryOptionMutationResponse,
  CategoryMutationResponse,
} from 'graphql/__generated__/types'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { getTotalItemsSelected } from 'components/DataTableSlim/Utils'

interface Props {
  pods: Pod[]
  customCategories: Category[]
  locationCategories: Category[]
  totalPods: number
  loading: boolean
  fetchCsvData: () => Promise<CategoryManagerPodsQueryResult>
  applyCategory: (
    categoryId: string,
    optionId?: string,
    displayIdToInclude?: string
  ) => Promise<number>
  createCategoryOption: (
    categoryId: string,
    optionName: string,
  ) => Promise<CategoryOptionMutationResponse>
  updateCategoryOption: (
    categoryId: string,
    optionId: string,
    optionName: string,
  ) => Promise<CategoryOptionMutationResponse>
  deleteCategoryOption: (
    categoryId: string,
    optionId: string,
  ) => Promise<number>
  createCategory: (
    categoryName: string,
  ) => Promise<CategoryMutationResponse>
  updateCategory: (
    categoryId: string,
    categoryName: string,
  ) => Promise<CategoryMutationResponse>
  deleteCategory: (
    categoryId: string,
  ) => Promise<number>
  totalFilteredPods: number
}

export const CategoryPage = ({
  pods,
  customCategories,
  locationCategories,
  totalPods,
  loading,
  fetchCsvData,
  applyCategory,
  createCategoryOption,
  updateCategoryOption,
  deleteCategoryOption,
  createCategory,
  updateCategory,
  deleteCategory,
  totalFilteredPods,
}: Props) => {
  const { selectedItems } = useDataTable<Pod>(DataTableInstance.Categories)
  const totalItemsSelected = getTotalItemsSelected(selectedItems, totalFilteredPods)

  return (
    <div className={styles.parent}>
      <SolFilterHeaderCard
        header="Categories"
        filteredItems={selectedItems.includedIds?.includes('*')
           && selectedItems?.excludedIds?.length === 0 ? totalFilteredPods : totalItemsSelected}
        tableId={DataTableInstance.Categories}
        totalFilteredPods={totalFilteredPods}
      />
      <ContentWrapper>
        <CategoryEditor
          categories={[...customCategories, ...locationCategories]}
          totalPods={totalPods}
          loading={loading}
          createCategoryOption={createCategoryOption}
          updateCategoryOption={updateCategoryOption}
          deleteCategoryOption={deleteCategoryOption}
          createCategory={createCategory}
          updateCategory={updateCategory}
          deleteCategory={deleteCategory}
        />
        <div className={styles.padBottom}>
          <CategoriesTable
            pods={pods}
            categories={customCategories}
            loading={loading}
            fetchCsvData={fetchCsvData}
            applyCategory={applyCategory}
            totalFilteredPods={totalFilteredPods}
          />
        </div>
      </ContentWrapper>
    </div>
  )
}
